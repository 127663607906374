import { Box } from "@mui/material";
import { Paid } from "components/icons";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

export const MergerInfo = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.merger}>
      <Paid />
      <T bodySmall>
        <FormattedMessage {...i18n.description} />
      </T>
    </Box>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  merger: {
    maxWidth: 430,
    display: "flex",
    gap: 6,
    background: theme.palette.greyLight100,
    borderRadius: 6,
    padding: 8,
    marginBottom: 24,
    "& svg": {
      width: 16,
    },
  },
}));

const i18n = defineMessages({
  description: {
    id: "MergerInfo.description",
    defaultMessage: `Reveal merged with Crossbeam to create the world's #1 platform for ecosystem led growth, and you are just few steps away from using all the benefits!`,
  },
});
